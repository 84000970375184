<div class="loading-content cdk-global-overlay-wrapper">
  <div class="container">
    <img [style]="{ height: 'auto', zIndex: 99 }" alt="" src='../../../../assets/images/loading.gif' />
<!--    <div class="loading-img" id="loading-img">-->
<!--      <div class="loadsquare square-1"></div>-->
<!--      <div class="loadsquare square-2"></div>-->
<!--      <div class="loadsquare square-3"></div>-->
<!--    </div>-->
  </div>
</div>
