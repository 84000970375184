import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { removeParamNullOrUndefined } from '@cores/functions';
import { HttpWrapper } from '@cores/http-wrapper';
import { environment } from '@env';
import { StorageService } from '@services';
import { map, Observable } from 'rxjs';
import { ApplyTransferOfPersonnelModel, TransferOfPersonnelSearch } from '../models/transfer-of-personnel.model';

@Injectable({
  providedIn: 'root',
})
export class TransferOfPersonnelService extends HttpWrapper {
  constructor(http: HttpClient) {
    super(http, `${environment.base_url_employee}`);
  }

  search(params?: TransferOfPersonnelSearch): Observable<any> {
    return this.http.get(`${this.baseURL}/employee-mapping`, { params: removeParamNullOrUndefined(params) });
  }

  fileExportSearch(fileName: string, param: TransferOfPersonnelSearch): Observable<any> {
    return this.http.get(`${this.baseURL}/employee-mapping/export`, { responseType: 'arraybuffer' }).pipe(
      map((res) => {
        saveAs(
          new Blob([res], {
            type: 'application/octet-stream',
          }),
          fileName
        );
        return true;
      })
    );
  }

  getListEdit(id: number): Observable<any> {
    return this.http.get(`${this.baseURL}/employee-mapping/${id}`);
  }
  getListError(id: number): Observable<any> {
    return this.http.get(`${this.baseURL}/employee-mapping/error-page/${id}`);
  }

  postEditList(id: number, params: FormData): Observable<any> {
    return this.http.post(`${this.baseURL}/employee-mapping/${id}`, params);
  }

  applyList(params: ApplyTransferOfPersonnelModel, listId: any): Observable<any> {
    let listParam = { ...params, ...listId };
    return this.http.post(`${this.baseURL}/employee-mapping/apply`, removeParamNullOrUndefined(listParam));
  }

  initData(): Observable<any> {
    return this.http.get(`${this.baseURL}/employee-mapping/init`);
  }

  /* Import */
  fileExport(fileName: string): Observable<any> {
    return this.http.get(`${this.baseURL}/employee-mapping/export-template-file`).pipe(
      map((res: any) => {
        saveAs(
          new Blob([this.str2ab(res.content)], {
            type: 'application/octet-stream',
          }),
          fileName
        );
        return true;
      })
    );
  }

  str2ab(str: string) {
    let binary_string = window.atob(str);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  importFile(params: any): Observable<any> {
    return this.http.post(this.baseURL + `/employee-mapping/import`, params);
  }

  getDataTrue(uuidImport: string, statusImport: string, params: TransferOfPersonnelSearch): Observable<any> {
    const param = {
      ...params,
      type: statusImport,
      uuidImport: uuidImport,
    };
    return this.http.get(this.baseURL + `/employee-mapping/temp`, {
      params: removeParamNullOrUndefined(param),
    });
  }

  fileExportImport(fileName: string, fileId: string, statusImport: string): Observable<any> {
    return this.http.get(`${this.baseURL}/employee-mapping/export-temp?type=${statusImport}&uuidImport=${fileId}`).pipe(
      map((res: any) => {
        saveAs(
          new Blob([this.str2ab(res.content)], {
            type: 'application/octet-stream',
          }),
          fileName
        );
        return true;
      })
    );
  }

  getDataTitle(unitCode: any) {
    return this.http.get(`${environment.base_url_organization}` + `/organization-chart/draft-by-unit`, { params: unitCode });
  }

  getCostCenter(unitCode: any, titleCode: any) {
    return this.http.get(
      `${environment.base_url_organization}` + `/organization-chart/cost-center-draft?unitCode=${unitCode}&titleCode=${titleCode}`
    );
  }
}

@Injectable({ providedIn: 'root' })
export class LevelHeaderSelectizeResolve implements Resolve<Object> {
  constructor(private router: Router, private api: TransferOfPersonnelService, private storage: StorageService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.api.get('hris-organization/organization-chart/level?draft=true');
  }
}
